import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Media } from "../../hooks/apis/media";
import { Texts } from "../../utils/texts";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { figmapx2vh, figmapx2vw } from "../../utils/utils";
import { KEYS } from "../../utils/keys";
import { memo, useMemo, useRef } from "react";
import { PlayerContext } from "./player";
import { SESSION } from "../../utils/session";

const FilterByLang = ({ filterData, setSelected, itemLangSelected }) => {
    const SELECTOR_ID = "langFilterSelector";
    const BTN_FILTER_ID = "btn-filter";

    const dispatch = useDispatch();

    //Store data
    const texts = useSelector((state) => state.ui.texts);

    //States
    const [langs, setLangs] = useState(null);
    const [currentLang, setCurrentLang] = useState(0);
    const [selector, showSelector] = useState(false);

    const selectorRef = useRef();
    const currentLangRef = useRef();

    if (!filterData) {
        return null;
    }

    if (itemLangSelected) {
        currentLangRef.current = filterData.findIndex((element) => element.id === itemLangSelected.id);
    }

    useEffect(() => {
        setLangs(filterData);
        const lastIndexSelected = filterData.findIndex((element) => element.id === itemLangSelected.id);
        setCurrentLang(lastIndexSelected);
        currentLangRef.current = lastIndexSelected;
    }, []);

    useEffect(() => {
        selectorRef.current = selector;
        currentLangRef.current = currentLang;
        if (selector) {
            focus.value.replace(SELECTOR_ID);
        }
    }, [selector]);

    const isFocused = (id) => {
        return focus.value.current === id;
    };

    const hideSelector = (options) => {
        setCurrentLang(currentLangRef.current);
        showSelector(false);
        if (options?.refocusBtn) {
            setTimeout(() => {
                focus.value.replace(BTN_FILTER_ID);
            }, 20);
        }
    };

    const onFilterBtnkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            switch (keyData.value) {
                case KEYS.left:
                    eventHandler.stopPropagation(e);
                    break;
                case KEYS.enter:
                    showSelector(true);
                    break;
                case KEYS.back:
                    if (selectorRef.current) {
                        hideSelector();
                    }
                    break;

                default:
                    break;
            }
        }
        if (selectorRef.current) {
            eventHandler.stopPropagation(e);
            return;
        }
    };

    const onSelectorkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            switch (keyData.value) {
                case KEYS.up:
                    setCurrentLang(langs?.[currentLang + 1] ? currentLang + 1 : 0);
                    break;
                case KEYS.down:
                    setCurrentLang(langs?.[currentLang - 1] ? currentLang - 1 : langs?.length - 1);
                    break;
                case KEYS.enter:
                    currentLangRef.current = currentLang;
                    setSelected(langs[currentLang]);
                    hideSelector();
                    break;
                case KEYS.back:
                    hideSelector({ refocusBtn: true });
                    break;
                default:
                    break;
            }
        }
        eventHandler.stopPropagation(e);
    };

    return useMemo(() => {
        return (
            <div
                id={BTN_FILTER_ID}
                onKeyDown={(e) => {
                    onFilterBtnkeydown(e);
                }}
                tabIndex={0}
                focusClass="channelList-focus"
                className={`icon icon-filter relative navigable h-full rounded ${
                    selector ? "bg-white text-gray-900" : ""
                }`}
                style={{
                    paddingLeft: `1vw`,
                    paddingRight: `1vw`,
                    lineHeight: `${figmapx2vh(70)}`,
                    width: langs && langs?.[currentLangRef.current].id !== -1 ? figmapx2vw(327) : figmapx2vw(75),
                }}
            >
                {langs && langs?.[currentLangRef.current]?.id !== -1 && (
                    <div
                        className={"float-left absolute"}
                        style={{
                            marginLeft: "3vw",
                            marginTop: "-7vh",
                            height: figmapx2vh(70),
                            width: figmapx2vw(237),
                        }}
                    >
                        <span className={"float-left"}>[</span>
                        <div
                            className={"float-left"}
                            style={{
                                fontSize: figmapx2vh(40),
                                height: figmapx2vh(70),
                                maxWidth: figmapx2vw(207),
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                wordBreak: "break-all",
                                webkitLineClamp: 1,
                                webkitBoxOrient: "vertical",
                            }}
                        >
                            {`${langs?.[currentLangRef.current].name}`}
                        </div>
                        <span className={"float-left"}>]</span>
                    </div>
                )}
                {selector ? (
                    <div style={{ marginLeft: "-1vw", marginTop: "1vh", width: figmapx2vw(327) }}>
                        <div
                            className={"lato vertical-middle text-center bg-gray-200 font-600"}
                            style={{ fontSize: figmapx2vw(30) }}
                        >
                            {Texts.translateCap(texts, "audio-subtitles")}
                        </div>
                        <div
                            onKeyDown={(e) => {
                                onSelectorkeydown(e);
                            }}
                            id={`${SELECTOR_ID}`}
                            focusClass="channelList-focus"
                            tabIndex={0}
                            className={"selector w-full table navigable absolute channelList-focus text-center"}
                            style={{
                                width: figmapx2vw(327),
                                fontSize: figmapx2vw(30),
                                fontWeight: 400,
                                height: figmapx2vh(139),
                            }}
                        >
                            <div className={"table-cell lato vertical-middle"} style={{ lineHeight: 1 }}>
                                {langs?.[currentLang]?.name || texts["unavailable"]}
                                {langs?.length > 1 ? (
                                    <div>
                                        <div className="icon icon-chevron-up absolute" />
                                        <div className="icon icon-chevron absolute" />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    });
};

export default memo(FilterByLang);
