/* eslint-disable no-undef */
import { dispatchNewMediaEvent, EVENTS } from "../../../../utils/eventsConst";
import { getLanguageNameFromIso6392 } from "../../../../utils/iso639/ISO639";
import { displaySubtitleInTizen, SUBTITLE_BASE_URL, SUBTITLE_DIV_ID, TIZEN_LOCAL_PATH } from "../../../../utils/movies";
import { getLanguageNameFromIso, vh2px, vw2px, writeLog } from "../../../../utils/utils";
import { STB } from "./stb";

export const Media = {
    currentVideoSize: null,
    intendsToStop: 0,
    ensureStopTimeout: null,
    _stop() {
        try {
            let state = webapis.avplay.getState();
            if (state == "PLAYING" || state == "PAUSED") {
                writeLog("paro video");
                webapis.avplay.stop();
                webapis.avplay.close();
            }
        } catch (error) {
            console.log("ERROR - en _stop");
        }
        this.ensureStop();
    },

    pause() {
        samsung.video.pause();
    },
    resume() {
        samsung.video.resume();
    },
    setStreamPos: function (newpos, delay) {
        try {
            samsung.video.jump(parseInt(newpos * 1000));
        } catch (error) {
            samsung.video.jump(parseInt(newpos * 1000));
        }
    },
    getStreamPos: function () {
        return samsung.video.getTime() / 1000;
    },
    ensureStop() {
        this.ensureStopTimeout = setTimeout(() => {
            try {
                let state = webapis.avplay.getState();
                // writeLog(`werr:${state}`);
                if (state !== "PLAYING") {
                    this.playingMedia = false;
                    // writeLog("playing a false");
                } else {
                    this.intendsToStop++;
                    if (this.intendsToStop < 10) {
                        this.ensureStop();
                    } else {
                        this.intendsToStop = 0;
                    }
                }
            } catch (error) {
                console.log("ERROR - en ensureStop");
            }
        }, 100);
    },

    kill() {
        try {
            let state = webapis.avplay.getState();
            if (state == "PLAYING" || state == "PAUSED") {
                webapis.avplay.stop();
                webapis.avplay.close();
            }
            if (state == "IDLE") {
                webapis.avplay.close();
            }
            samsung.isPlayer = false;
            samsung.isPlaying = false;
            samsung.isDVBT = false;
            samsung.hideSource();
        } catch (error) {
            console.log(`error: ${error.name}`);
        }
    },
    setVideoSize(x, y, width, height) {
        this.currentVideoSize = {
            x,
            y,
            width,
            height,
        };

        //set video size
        try {
            x = vw2px(x);
            y = vh2px(y);
            width = vw2px(width);
            height = vh2px(height);

            samsung.video.resize(x, y, width, height);
            this.showVideoOnFront();
        } catch (error) {
            console.log("ERROR de ssamsung.video.resize");
        }

        //Make hole in the app to show TV video

        let connectedVideoSources;

        function systemInfoSuccessCB(videoSource) {
            connectedVideoSources = videoSource.connected;
            for (let i = 0; i < connectedVideoSources.length; i++) {
                if (connectedVideoSources[i].type === "TV") {
                    // set TV as input source of TV hole window
                    tizen.tvwindow.setSource(
                        connectedVideoSources[i],
                        function () {
                            console.log("[setSource] TV - success");
                        },
                        function (e) {
                            console.log("[setSource] TV - error " + e.message);
                        },
                    );
                    break;
                }
            }
        }

        //Get available TV sources and set TV  as input source of TV hole window
        try {
            tizen.systeminfo.getPropertyValue("VIDEOSOURCE", systemInfoSuccessCB, () => {});
        } catch (error) {}
    },

    ensureVideoSize() {
        if (this.currentVideoSize) {
            this.setVideoSize(
                this.currentVideoSize.x,
                this.currentVideoSize.y,
                this.currentVideoSize.width,
                this.currentVideoSize.height,
            );
        }
    },

    _playChannel(ip, port) {
        try {
            Media.kill();

            webapis.avplay.open(`udp://${ip}:${port}`);
            webapis.avplay.prepare();
            webapis.avplay.play();
        } catch (e) {
            writeLog(`error: ${e.message}`, e);
        }
    },

    _playHLSVideo(url) {
        if (this.ensureStopTimeout) {
            clearTimeout(this.ensureStopTimeout);
        }
        try {
            Media.kill();
            webapis.avplay.open(url);
            webapis.avplay.prepare();
            // webapis.avplay.play();
            // pretend to make more fluent jump forward in EF690
            webapis.avplay.setBufferingParam("PLAYER_BUFFER_FOR_PLAY", "PLAYER_BUFFER_SIZE_IN_SECOND", 30);

            webapis.avplay.setListener({
                onstreamcompleted: function () {
                    dispatchNewMediaEvent(EVENTS.MEDIA.STATUS_END_OF_STREAM);
                },
                onevent: function (eventType, eventData) {
                    console.log({ eventData });
                    if (eventType == "PLAYER_MSG_BITRATE_CHANGE") {
                        console.log("PLAYER_MSG_BITRATE_CHANGE");
                        dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_SUCCESS);
                    } else if (eventType == "PLAYER_MSG_STREAM_INFO_READY") {
                        console.log("PLAYER_MSG_STREAM_INFO_READY");
                        // dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_SUCCESS);
                    } else if (eventType == "PLAYER_ERROR_NOT_SUPPORTED_FORMAT") {
                        dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_ERROR);
                    }
                },
                onsubtitlechange: function (duration, text, data3, data4) {
                    displaySubtitleInTizen(text);
                },
                onerror: function () {
                    dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_ERROR);
                },
            });
            setTimeout(() => {
                webapis.avplay.play();
            }, 1);
        } catch (e) {
            writeLog(`error: ${e.message}`);
        }
    },

    _playDVBTChannel: function (channel) {
        Media.kill();
        console.debug("Would play DVBT: " + JSON.stringify(channel));
        samsung.video.playDVBT(channel.frequency, channel.isc);
    },
    _playDVBCChannel: function (channel) {
        Media.kill();
        console.debug("Would play DVBC: " + JSON.stringify(channel));
        samsung.video.playDVBC(channel.frequency, channel.channeltype, channel.isc, channel.symbol_rate, channel.qam);
    },
    _playDVBSChannel: function (channel) {
        Media.kill();
        console.debug("Would play DVBS: " + JSON.stringify(channel));
        samsung.video.playDVBS(channel.frequency, channel.isc, channel.satelliteId);
    },
    _playATSCChannel: function (channel) {
        Media.kill();
        console.debug("Would play ATSC: " + JSON.stringify(channel));
        samsung.video.playATSC(
            channel.ptc,
            channel.minor,
            channel.modulationtype,
            channel.channeltype,
            channel.tvmode,
            channel.major,
            channel.programnumber,
        );
    },

    getAudioList(onSuccess) {
        try {
            let tracks = samsung.video.getAudioTracks();
            const otracks = samsung.video.getAudioTracks();
            console.log({ otracks });
            // models AU800 and Q60 with firmware lower than 2221.1 need to reverse audio tracks
            if (
                (samsung.getModel()?.indexOf("AU800") > -1 || samsung.getModel()?.indexOf("Q60") > -1) &&
                parseFloat(localStorage.getItem("firmwareVersion").replace("T-NKLADEUCB-", "")) < 2221.1
            ) {
                tracks = tracks?.reverse();
            }
            let audioList = [];
            if (!tracks) {
                onSuccess(audioList);
                return;
            }
            let _name = getLanguageNameFromIso6392(tracks[i]["language"]);
            if (!_name) {
                _name = "language " + (i + 1);
            }
            for (let i = 0; i < tracks.length; i++) {
                audioList.push({
                    id: i,
                    name: getLanguageNameFromIso6392(tracks[i]["language"]) || "language " + (i + 1),
                    iso: tracks[i]["language"],
                });
            }
            onSuccess(audioList);
        } catch (error) {
            onSuccess([]);
        }
    },

    getSubtitleList(onSuccess) {
        let tracks = samsung.video.getSubtitleTracks();

        try {
            let subtitleList = [];
            if (!tracks) {
                onSuccess(subtitleList);
                return;
            }

            for (let i = 0; i < tracks.length; i++) {
                subtitleList.push({
                    id: i,
                    name: getLanguageNameFromIso(
                        typeof tracks[i]["track_lang"] != "undefined" ? tracks[i]["track_lang"] : tracks[i]["language"],
                    ),
                });
            }
            onSuccess(subtitleList);
        } catch (error) {
            onSuccess([]);
        }
    },

    changeAudio(ind) {
        samsung.video.changeAudio(ind);
    },

    changeSubtitle(ind, movie) {
        samsung.video.setSubtitleOn();
        if (movie) {
            const movieSubDiv = document.getElementById(SUBTITLE_DIV_ID);
            if (movieSubDiv) {
                movieSubDiv.classList.remove("hidden");
            }
            const subtitle = movie.subtitles.find((sub) => sub.id === ind);
            const tvLocalUrlSubtitleFile = TIZEN_LOCAL_PATH + subtitle.file;
            console.log(`url subtitulo: ${tvLocalUrlSubtitleFile}`);
            //download subtitle
            let downloadListener = {
                oncompleted: function (id, path) {
                    // SET SUBTITLE DOWNLOADED
                    samsung.video.setSubtitleUrl(tvLocalUrlSubtitleFile);
                },
            };
            samsung.download(SUBTITLE_BASE_URL + subtitle.file, downloadListener, subtitle.file);
        } else {
            samsung.video.changeSubtitle(ind);
        }
    },

    disableSubtitles: function () {
        const movieSubDiv = document.getElementById(SUBTITLE_DIV_ID);
        if (movieSubDiv) {
            movieSubDiv.classList.add("hidden");
        }
        samsung.video.hideSubs();
    },

    checkForceAudio: function (movie) {
        if (samsung.TEP) {
            // all languages are multi suffix => no need forceAudio
            let allMulti = true;
            movie?.languages.forEach((element) => {
                if (element.fileSuffix !== "multi") {
                    allMulti = false;
                }
            });
            if (allMulti) {
                return false;
            }
            return STB.forceMovieLanguage && movie?.languages?.length > 2 && STB?.isTizen;
        } else if (STB.model.indexOf("EF690") > -1) {
            // model EF690 uses forceAudio, but docker has last version of streamer, so always is audio in same language
            return true;
        } else {
            return false;
        }
    },
};
