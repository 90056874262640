import { useEffect, useRef, useState } from "preact/hooks";
import Image from "../../components/reusable/image";
import { DEFAULT, STATE, STORAGE_KEY } from "../../utils/constants";
import {
    cacheContent,
    calculateElapseTimeForCurrentLoop,
    createTimeTable,
    fullContentDuration,
    isInHourRange,
    parseHourMinute,
    ScheduleDailyType,
    setDateSignageScheduleTime,
    SignageContentType,
} from "../../utils/signageUtils";
import { completeDasURL, formatSeconds, inStandbyMode } from "../../utils/utils";
import getScreen from "../../hooks/GraphqlCalls/getScreen";
import { SESSION } from "../../utils/session";
import Screen from "../../components/screen";
import LibraryContent from "../../components/libraryContent";
import { forceBackgroundRestore, setVideoPos } from "../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { Media } from "../../hooks/apis/media";
import { Logger } from "../../utils/logger";
import OutOfContent from "../../components/ContactHotelStaff";
import { Texts } from "../../utils/texts";
import { STB } from "../../hooks/apis/stb";

const PlayCurrentSequence = ({ sequence }) => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);

    const [index, setIndex] = useState(null);
    const [screenData, setScreenData] = useState(null);
    const currentSequence = useRef(sequence);
    const timeTable = useRef();

    const indexContentPlaying = useRef({ index: null });
    const nextContentTime = useRef();
    const clock = useRef();

    useEffect(() => {
        if (sequence) {
            currentSequence.current = sequence;
            indexContentPlaying.current = null;
            timeTable.current = createTimeTable(currentSequence.current);
            // delay to start sequence in next 5th multiply second
            const _n = new Date();
            _n.getSeconds();
            if (_n.getSeconds() % 5 > 0) {
                setTimeout(
                    () => {
                        findContentToPlay();
                        start1sClock();
                    },
                    (5 - (_n.getSeconds() % 5)) * 1000 - _n.getMilliseconds(),
                );
            } else {
                findContentToPlay();
                start1sClock();
            }
        }
    }, [sequence]);

    const start1sClock = () => {
        if (clock.current) {
            clearTimeout(clock.current);
        }
        function getTimeUntilNextSecond() {
            const now = new Date();
            return 1000 - now.getMilliseconds();
        }
        // Función para iniciar el evento en el cambio de segundos
        clock.current = setTimeout(() => {
            const _checkTime = new Date();
            if (_checkTime > timeTable.current[timeTable.current.length - 1].end) {
                timeTable.current = createTimeTable(currentSequence.current);
            }
            findContentToPlay();
            start1sClock();
        }, getTimeUntilNextSecond());
    };

    const playContent = () => {
        const currentContent = currentSequence.current.contents[indexContentPlaying.current.index];
        // if (Media && (Media.playingMedia || Media.playingChannel)) {
        //     Media.showVideoOnBack();
        //     Media.stop({ keepLastVideo: true });
        //     dispatch(setVideoPos(null));
        // }
        // if (currentSequence.current?.contents?.length == 1) {
        //     if (currentContent?.type == SignageContentType.VIDEO) {
        //         Media.replayLastVideo();
        //     }
        // }

        if (inStandbyMode()) {
            return <OutOfContent message={"Signage sequence in stand-by mode"} />;
        }
        switch (currentContent?.type) {
            case SignageContentType.ASSET:
            case SignageContentType.VIDEO:
                return <LibraryContent asset={currentContent.ref} jumpTo={indexContentPlaying.current.timeElapsed} />;
            case SignageContentType.SCREEN:
                return <Screen data={screenData} preventFocus={true} />;
            default:
                return null;
        }
    };

    const findContentToPlay = () => {
        let timeScheduleStarted = DEFAULT;
        if (
            !currentSequence.current.isDefault &&
            currentSequence.current.scheduleDailyType === ScheduleDailyType.TIME_RANGE
        ) {
            timeScheduleStarted =
                currentSequence.current.timeRanges?.find((range) => isInHourRange(range))?.startTime || DEFAULT;
        }
        const inicio = setDateSignageScheduleTime(timeScheduleStarted);

        const horaActual = new Date();

        const duraciones = currentSequence.current.contents.map((content) => content.duration);
        let segundosTranscurridos = Math.floor((horaActual - inicio) / 1000);
        let duracionTotal = duraciones.reduce((a, b) => a + b, 0);
        let segundosEnCiclo = segundosTranscurridos % duracionTotal;

        let tiempoAcumulado = 0;
        let tiempoTranscurrido = 0;
        for (let i = 0; i < duraciones.length; i++) {
            if (segundosEnCiclo < tiempoAcumulado + duraciones[i]) {
                tiempoTranscurrido = segundosEnCiclo - tiempoAcumulado;
                // Si la imagen ha llegado a su tiempo límite, pasamos a la siguiente
                if (tiempoTranscurrido >= duraciones[i]) {
                    tiempoTranscurrido = 0;
                    break;
                }
                break;
            }
            tiempoAcumulado += duraciones[i];
        }
        const _contentRange = timeTable.current.find((range) => range.start <= horaActual && range.end > horaActual);

        if (!indexContentPlaying.current || _contentRange.index !== indexContentPlaying.current.index) {
            indexContentPlaying.current = { index: _contentRange.index, timeElapsed: tiempoTranscurrido };
            // pre-cache next content
            cacheContent(
                currentSequence.current.contents[
                    indexContentPlaying.current.index + 1 < currentSequence.current.contents.length
                        ? indexContentPlaying.current.index + 1
                        : 0
                ],
            );
            const currentContent = currentSequence.current.contents[indexContentPlaying.current.index];
            switch (currentContent?.type) {
                case "SCREEN":
                    dispatch(forceBackgroundRestore());
                    getScreen(currentContent.ref, SESSION.currentSignage.designId, (data) => {
                        if (!data) {
                            STB.reload();
                        }
                        setScreenData(data);
                    });
                    break;
                default:
                    break;
            }
            setIndex(_contentRange.index);
        }
    };

    return <>{indexContentPlaying?.current?.index > -1 && currentSequence.current ? playContent() : null}</>;
};

export default PlayCurrentSequence;
