import { SESSION } from "../../../utils/session";
import { ScheduleDailyType } from "../../../utils/signageUtils";
import { BANNER, Banner, getBannerAxisPosition } from "../../../utils/tickerTapeAndBannerUtils";
import { completeDasURL, figmaHeightPx2Px, getDasExternalUrl } from "../../../utils/utils";
import Query from "../../query";

export const getBanners = (onSuccess?: (banners: Banner[] | null) => void) => {
    Query({
        query: `{
                    bannerContent {
                    md5
                    banners {
                        available
                        id
                        name
                        contentAction
                        contentImage
                        displayPosition
                        displaySize
                        scheduleDaysOfWeek
                        scheduleDaysType
                        scheduleDuration
                        scheduleEnd
                        scheduleFrequencyDisplay
                        scheduleFrequencyLimited
                        scheduleObtrusiveMode
                        scheduleStart
                        timeRanges {
                            endTime
                            startTime
                        }
                    }
                    }
                }`,
        onResponse(res) {
            if (res?.data?.bannerContent?.banners.length === 0) {
                SESSION.currentBanners.md5 = res?.data?.bannerContent?.md5;
                if (onSuccess) {
                    // no data
                    onSuccess(null);
                }
            } else if (res?.data?.bannerContent?.banners) {
                SESSION.currentBanners.md5 = res?.data?.bannerContent?.md5;
                if (onSuccess) {
                    const _allFullBanners = res?.data?.bannerContent?.banners?.map((_bnr: Banner) => ({
                        ..._bnr,
                        scheduleDailyType: ScheduleDailyType.TIME_RANGE,

                        content: {
                            asset: _bnr.contentImage?.libraryRef
                                ? completeDasURL(_bnr.contentImage.libraryRef, {
                                      width: "",
                                      height: figmaHeightPx2Px(BANNER.SIZE[_bnr.displaySize].height),
                                  })
                                : getDasExternalUrl(_bnr.contentImage.externalUrl || "", {
                                      h: figmaHeightPx2Px(BANNER.SIZE[_bnr.displaySize].height),
                                      pixelRatio: 1,
                                  }),
                            navigation:
                                _bnr?.contentAction && _bnr?.contentAction?.type !== "disabled"
                                    ? _bnr?.contentAction
                                    : "",
                        },
                        position: getBannerAxisPosition(_bnr.displayPosition),
                    }));

                    onSuccess(_allFullBanners);
                }
            } else if (res && (!res.data || (res.data && !res.data.bannerContent) || res?.error)) {
                console.log("SOME ERRORS bannerContent " + res?.error);
            }
        },
    });
};
