import { useEffect, useState } from "react";
import { CHANNELS } from "../../utils/constants";
import focus from "../../utils/focus";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { figmapx2vh, figmapx2vw, getEnvVar } from "../../utils/utils";
import ChannelGroup from "./channelGroup";
import ChannelLangSelector from "./channelLangSelector";
import ChannelFavouriteSelector from "./channelFavouriteSelector";
import ChannelSubtitleSelector from "./channelSubtitleSelector";
import { useSelector } from "react-redux";
import { Texts } from "../../utils/texts";
import { useRef } from "preact/hooks";

const CHANNEL_INFO_TIMEOUT = 5000; // in milliseconds

const ChannelInfo = ({
    groups,
    filter,
    setFilter,
    playingChannel,
    tvState,
    setTvState,
    setPlayingChannel,
    GROUP_FILTERS,
}) => {
    const [groupsToShow, setGroupsToShow] = useState([]);
    const [firstFocus, setFistFocus] = useState(true);
    const favouriteChannelIDs = useSelector((state) => state.status.locationData.favouriteChannelIDs);
    const texts = useSelector((state) => state.ui.texts);
    const popup = useSelector((state) => state.ui.popup);
    let showChannelInfoTimeout = useRef();

    useEffect(() => {
        let groupList = [];
        groups
            ?.find((group) => group.id === filter)
            ?.items.map((channelGroup) => {
                channelGroup.items.find((channel) => channel.num === playingChannel.num) &&
                    groupList.push(channelGroup);
            });

        groupList.push({
            id: 100,
            name:
                filter === CHANNELS.FILTERS.MY_LIST
                    ? Texts.capitalize(texts["all channels"])
                    : GROUP_FILTERS?.find((iFilter) => iFilter.id === filter)?.name,
            items: [{ id: -1 }, { id: -2 }, { id: -3 }, { id: -4 }, { id: -5 }, { id: -6 }, { id: -7 }, { id: -8 }],
        });
        setGroupsToShow(groupList);
    }, [filter]);

    useEffect(() => {
        if ((!favouriteChannelIDs || favouriteChannelIDs.length === 0) && filter === CHANNELS.FILTERS.MY_LIST) {
            setFilter(CHANNELS.FILTERS.ALL);
        }
    }, [favouriteChannelIDs]);

    useEffect(() => {
        if (groupsToShow?.length > 0 && firstFocus) {
            focus.value.replace(`btn-channel_${playingChannel?.num}`);
            setFistFocus(false);
        }
    }, [groupsToShow]);

    useEffect(() => {
        startChannelInfoTimeout();
        return () => {
            clearTimeout(showChannelInfoTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (popup) {
            clearTimeout(showChannelInfoTimeout.current);
        } else {
            startChannelInfoTimeout;
        }
    }, [popup]);

    const startChannelInfoTimeout = () => {
        clearTimeout(showChannelInfoTimeout.current);
        showChannelInfoTimeout.current = setTimeout(() => {
            setTvState(CHANNELS.TV_STATES.LIMBO);
        }, CHANNEL_INFO_TIMEOUT);
    };

    const toggleMenuState = () => {
        if (showChannelInfoTimeout.current) {
            clearTimeout(showChannelInfoTimeout.current);
            showChannelInfoTimeout.current = null;
        } else {
            startChannelInfoTimeout();
        }
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        startChannelInfoTimeout();
        if (keyData) {
            switch (keyData.value) {
                case KEYS.back:
                    setTvState(CHANNELS.TV_STATES.LIMBO);
                    preventDefault = true;
                    break;
                case KEYS.down:
                    if (focus.value.current.indexOf("btn-channel") > -1) {
                        if (filter === CHANNELS.FILTERS.MY_LIST) {
                            setFilter(CHANNELS.FILTERS.ALL);
                        }
                        setTvState(CHANNELS.TV_STATES.CHANNEL_LIST);
                        preventDefault = true;
                    }
                    break;
                case KEYS.up:
                    if (focus.value.current.indexOf("btn-channel") > -1) {
                        focus.value.replace("btn-audio");
                        preventDefault = true;
                    }
                    break;

                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <div className={"fixed top-0 lef-0 z-100 table w-full h-full channelInfo "}>
                <div
                    style={{
                        paddingLeft: `${figmapx2vw(72)}`,
                        paddingRight: `${figmapx2vw(72)}`,
                    }}
                >
                    {/*CHANNEL NAME & ICON OPTIONS*/}
                    <div
                        className="bar"
                        style={{
                            marginTop: `${figmapx2vh(600)}`,
                            marginBottom: `${figmapx2vh(40)}`,
                            height: `${figmapx2vh(80)}`,
                            width: `94vw`,
                        }}
                    >
                        <div>
                            {playingChannel?.logofile ? (
                                <div
                                    className={`bg-center bg-contain bg-no-repeat float-left `}
                                    style={{
                                        width: `${figmapx2vw(96)}`,
                                        height: `${figmapx2vh(80)}`,
                                        backgroundColor: "#252626",
                                        borderRadius: `${figmapx2vw(4)}`,
                                        backgroundImage: `url('${
                                            playingChannel.logoURL ||
                                            `${getEnvVar("API")}ext/${CHANNELS.logoPath?.pRef}/gfx_base/contents/tv/${
                                                playingChannel.logofile
                                            }?center_crop=120x120`
                                        }')`,
                                    }}
                                />
                            ) : (
                                <div
                                    className={`white  icon-channels text-center  float-left`}
                                    style={{
                                        fontSize: "3vw",
                                        borderRadius: `${figmapx2vw(4)}`,
                                        width: `${figmapx2vw(96)}`,
                                        height: `${figmapx2vh(80)}`,
                                        backgroundColor: "#252626",
                                        lineHeight: `${figmapx2vh(80)}`,
                                    }}
                                ></div>
                            )}
                        </div>
                        <div
                            className={"nowrap mx-auto text-ellipsis overflow-hidden"}
                            style={{
                                paddingLeft: `${figmapx2vw(40)}`,
                                lineHeight: `${figmapx2vh(80)}`,
                                fontSize: `${figmapx2vw(32)}`,
                                width: `64%`,
                            }}
                        >
                            {playingChannel?.name}
                        </div>
                        <div
                            style={{
                                float: "right",
                            }}
                        >
                            <ChannelLangSelector playingChannel={playingChannel} toggleMenuState={toggleMenuState} />
                            <ChannelSubtitleSelector
                                playingChannel={playingChannel}
                                toggleMenuState={toggleMenuState}
                            />
                            <ChannelFavouriteSelector playingChannel={playingChannel} />
                        </div>
                    </div>

                    {/*CHANNEL GROUP */}

                    {groupsToShow.map((cgroup) => {
                        return (
                            <ChannelGroup
                                group={cgroup}
                                filter={filter}
                                setPlayingChannel={setPlayingChannel}
                                tvState={tvState}
                                setTvState={setTvState}
                                scrollToFocus={true}
                                resetChannelInfoTimeout={startChannelInfoTimeout}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ChannelInfo;
