import Button from "../../components/common/button";
import Limbo from "../../components/screen/limbo";
import { DEVICE_FUNCTION_LIST, PERMISSION } from "../../utils/constants";
import { figmapx2vh, figmapx2vw } from "../../utils/utils";
import { Texts } from "../../utils/texts";
import { useEffect } from "preact/hooks";
import focus from "../../utils/focus";
import { SESSION } from "../../utils/session";
import { useSelector } from "react-redux";

const _wrapperClass = "table float-left px-3";
const iconClasses = "inline-block pb-6 text-5xl pl-2";
const butonInputCustomClass = "text-center mx-auto py-2 text-2xl";
const buttonInputCustomStyle = {
    width: figmapx2vw(208),
    height: figmapx2vh(208),
    backgroundColor: "#F5F6F8",
    color: "#2E3843",
};

export const ROOMTV_CONTENTS = {
    SIGNAGESEQUENCES: {
        supported: () => SESSION.hasPermission(PERMISSION.PRODUCT_SIGNAGE),
        name: "signage",
        id: "SIGNAGESEQUENCES",
        focus: "btn-signage",
        button: (_name) => (
            <Button
                data={{
                    id: "signage",
                    outline: true,
                    focusClass: "underSlashFocus",
                    wrapperClass: _wrapperClass,
                    name: _name,
                    icon: "advertising",
                    iconPosition: "top",
                    iconSize: iconClasses,
                    rounded: true,
                    customClass: butonInputCustomClass,
                    customStyle: buttonInputCustomStyle,
                }}
            />
        ),
    },

    TVCHANNELS: {
        supported: () => SESSION.hasPermission(PERMISSION.PRODUCT_CHANNELS),
        name: "tv-channels",
        id: "TVCHANNELS",
        focus: "btn-tv-channels",
        button: (_name) => (
            <Button
                data={{
                    id: "tv-channels",
                    outline: true,
                    focusClass: "underSlashFocus",
                    wrapperClass: _wrapperClass,
                    name: _name,
                    icon: "channels",
                    iconPosition: "top",
                    iconSize: iconClasses,
                    rounded: true,
                    customClass: butonInputCustomClass,
                    customStyle: buttonInputCustomStyle,
                }}
            />
        ),
    },
    CASTINSTRUCTIONSCREEN: {
        supported: (chromecastModel) => chromecastModel && SESSION.hasPermission(PERMISSION.PRODUCT_CAST),
        name: "cast",
        id: "CASTINSTRUCTIONSCREEN",
        focus: "btn-cast",
        button: (_name) => (
            <Button
                data={{
                    id: "cast",
                    outline: true,
                    focusClass: "underSlashFocus",
                    wrapperClass: _wrapperClass,
                    name: _name,
                    faIcon: { lib: "fab", name: "chromecast" },
                    iconSize: "pl-2 text-5xl",
                    rounded: true,
                    customClass: butonInputCustomClass,
                    customStyle: buttonInputCustomStyle,
                }}
            />
        ),
    },
};

const getContentName = (content, texts) => {
    if (content === ROOMTV_CONTENTS.TVCHANNELS.id) {
        return Texts.translateCap(texts, ROOMTV_CONTENTS[content].name);
    }
    return Texts.capitalize(ROOMTV_CONTENTS[content].name);
};

export const buildMenuContents = (locationData, texts) => {
    let menuContents = [];
    let firstItemId = null;
    const ORDER_OF_MENU = [
        ROOMTV_CONTENTS.SIGNAGESEQUENCES.id,
        ROOMTV_CONTENTS.TVCHANNELS.id,
        ROOMTV_CONTENTS.CASTINSTRUCTIONSCREEN.id,
    ];
    locationData.roomInfo.roomTvContents
        .sort((a, b) => ORDER_OF_MENU.indexOf(a) - ORDER_OF_MENU.indexOf(b))
        .map((content) => {
            if (ROOMTV_CONTENTS[content].supported(locationData.roomInfo.chromecastModel)) {
                menuContents.push(ROOMTV_CONTENTS[content].button(getContentName(content, texts)));

                if (!firstItemId) {
                    firstItemId = ROOMTV_CONTENTS[content].id;
                }
            }
        });
    return { menuContents, firstItemId };
};

export const storeContentSelected = (content) => {
    localStorage.setItem("commonZoneOptionKey", content);
};
export const getContentStored = () => {
    if (localStorage.getItem("commonZoneOptionKey")) {
        return localStorage.getItem("commonZoneOptionKey");
    }
    return null;
};

export const DisplayMenuContents = ({ menuContents, firstFocus }) => {
    const texts = useSelector((state) => state.ui.texts);

    useEffect(() => {
        if (getContentStored()) {
            focus.value.replace(ROOMTV_CONTENTS[getContentStored()].focus);
        } else {
            focus.value.replace(firstFocus);
        }
    }, []);

    return (
        <div
            className={`fixed  z-900 top-0 left-0`}
            style={{
                width: `${document.body.offsetWidth || window.innerWidth}px`,
                height: `${document.body.offsetHeight || window.innerHeight}px`,
                visibility: "visible",
            }}
        >
            <Limbo id={"commonZonesLimbo"} />
            <div id="pop-up-zone" className="table w-full h-full mx-auto">
                <div
                    className={"white "}
                    style={{
                        marginTop: "30vh",
                        position: "relative",
                        height: "70vh",
                        background:
                            "linear-gradient(0deg, rgba(55, 61, 66, 0.90) 45.02%, rgba(55, 61, 66, 0.71) 66.74%, rgba(55, 61, 66, 0.08) 90.9%, rgba(55, 61, 66, 0.00) 99.3%)",
                    }}
                >
                    <div class={"table h-full w-full mx-auto"}>
                        <div className="table-cell vertical-middle">
                            <div className={"text-center pb-8 text-4xl"} style={{ marginTop: "32vh" }}>
                                {Texts.translateCap(texts, "Tv-content")}
                            </div>
                            <div class="mx-auto table pt-2" style="width: auto">
                                <div className={"numberRow"}>{menuContents.map((item) => item)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
