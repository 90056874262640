/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef, useState } from "preact/compat";

import { useDispatch, useSelector } from "react-redux";
import { setMediaEvent } from "../actions/eventsActions";
import { forceBackgroundRestore, setBackground, setVideoPos } from "../actions/uiActions";
import { Media } from "../hooks/apis/media";
import { LOADING_STYLE, MESSAGES_STYLES } from "../utils/constants";
import { KEYS } from "../utils/keys";
import {
    completeDasURL,
    completeVOD_URL,
    printCenteredMessage,
    setVideoOnBackground,
    setVideoOnForeground,
} from "../utils/utils";
import Loading from "./loading";
import { eventHandler } from "../utils/eventHandler";
import Limbo from "./screen/limbo";
import focus from "../utils/focus";
import { dispatchErrorConnectionEvent, EVENTS } from "../utils/eventsConst";
import { SESSION } from "../utils/session";

const LibraryContent = ({ asset, jumpTo }) => {
    const dispatch = useDispatch();

    const texts = useSelector((state) => state.ui.texts);
    const reducerMediaEvent = useSelector((state) => state.events.mediaEvent);

    const [isVideo, setIsVideo] = useState(asset && asset.indexOf("video-") > -1);
    const [isImage, setIsImage] = useState(asset && asset.indexOf("video-") === -1);
    const [showVideoThumb, setShowVideoThumb] = useState(false);
    const [loadingResource, setLoadingResource] = useState(isImage);
    const [badResource, setBadResource] = useState(false);
    const popup = useSelector((state) => state.ui.popup);
    const videoSuccessRef = useRef(false);
    videoSuccessRef.current = false;

    const checkAsset = (_asset) => {
        const _isVideo = _asset?.indexOf("video-") > -1;
        const _isImage = _asset.indexOf("video-") === -1;
        setIsVideo(_isVideo);
        setIsImage(_isImage);
        if (_isVideo) {
            playVideo();
        } else if (_isImage) {
            Media.stop();
            setVideoOnForeground();
            let imageObj = new Image();

            imageObj.onload = function () {
                setLoadingResource(false);
                setBadResource(false);
            }.bind(setLoadingResource);
            imageObj.onerror = function () {
                setBadResource(true);
                if (SESSION.isCommonZone()) {
                    dispatchErrorConnectionEvent();
                }
            };
            imageObj.src = completeDasURL(_asset, { width: "full" });
        }
    };
    dispatch(setBackground(null));

    useEffect(() => {
        Media.stop();
        if (!SESSION.isCommonZone()) {
            focus.value.replace("limbo");
        }
        return () => {
            Media.stop();
            // dispatch(forceBackgroundRestore());
            setVideoOnForeground();
        };
    }, []);

    useEffect(() => {
        if (asset) {
            checkAsset(asset);
        }
    }, [asset]);

    useEffect(() => {
        if (reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_ERROR) {
            setBadResource(true);
            if (SESSION.isCommonZone()) {
                dispatchErrorConnectionEvent();
            }
        } else if (reducerMediaEvent?.code === EVENTS.MEDIA.STATUS_END_OF_STREAM) {
            setShowVideoThumb(true);
            dispatch(setMediaEvent(null));
            dispatch(setVideoPos({ x: 0, y: 0, width: 0.1, height: 0.1 }));
        } else if (reducerMediaEvent?.code === EVENTS.MEDIA.PLAY_SUCCESS) {
            setBadResource(false);
            if (videoSuccessRef.current) {
                dispatch(setMediaEvent(null));
                return;
            }
            if (!popup) {
                videoSuccessRef.current = true;
            }
            if (jumpTo) {
                Media.setStreamPos(jumpTo, 3000);
            }
            dispatch(setMediaEvent(null));
        }
    }, [reducerMediaEvent]);

    const playVideo = () => {
        setShowVideoThumb(null);
        setVideoOnBackground();
        Media.playHLSVideo(completeVOD_URL(asset));
        setTimeout(() => {
            dispatch(setVideoPos("fullscreen"));
        }, 100);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = true;
        if (keyData) {
            switch (keyData.value) {
                case KEYS.home:
                case KEYS.back:
                    preventDefault = false;
                    break;
                case KEYS.enter:
                    if (keyData.value == KEYS.enter && isVideo && !Media.playingMedia) {
                        playVideo();
                    }
                    break;
                default:
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            id="libraryContentDiv"
            style={{ width: document.body.offsetWidth, height: document.body.offsetHeight }}
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo />

            {badResource ? (
                printCenteredMessage(texts["file not found"], MESSAGES_STYLES.OVERLAY)
            ) : loadingResource ? (
                <Loading style={LOADING_STYLE} />
            ) : isImage ? (
                <div
                    className={`fixed top-0 left-0 bg-no-repeat bg-center bg-contain fade-in`}
                    style={{
                        width: "100vw",
                        height: "100vh",
                        transition: "background-image 0.5s ease-in-out",
                        backgroundImage: `url(${completeDasURL(asset ? asset : "", { width: "full" })})`,
                    }}
                />
            ) : isVideo && showVideoThumb ? (
                <div
                    className="fixed table top-0 left-0 w-full h-full bg-no-repeat bg-center bg-contain"
                    style={{
                        visibility: "visible",
                        backgroundImage: `url(${completeDasURL(`thumb/${asset}-${localStorage.getItem("lang")}`, {
                            width: "full",
                        })})`,
                    }}
                >
                    <div
                        className="table-cell fa fa-play-circle text-center vertical-middle "
                        style={{ fontSize: "8vw" }}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default memo(LibraryContent);
