import { SESSION } from "../../../utils/session";
import { ScheduleDailyType } from "../../../utils/signageUtils";
import { TickerTape } from "../../../utils/tickerTapeAndBannerUtils";
import Query from "../../query";

export const getTickerTapes = (onSuccess?: (tickerTapes: TickerTape[] | null) => void) => {
    Query({
        query: `{
                    tickerTapeContent{
                        md5
                        tickertapes{
                            id
                            name
                            available
                            content
                            displayScrollSpeed
                            displayTextSize
                            displayStyles
                            scheduleDaysOfWeek
                            scheduleDaysType
                            scheduleEnd
                            scheduleFrequencyDisplay
                            scheduleFrequencyLimited
                            scheduleFrequencyRepetitions
                            scheduleObtrusiveMode
                            scheduleStart
                            timeRanges{
                                endTime
                                startTime
                            }
                        }
                    }
                }`,
        onResponse(res) {
            if (res?.data?.tickerTapeContent?.tickertapes.length === 0) {
                SESSION.currentTickertapes.md5 = res?.data?.tickerTapeContent?.md5;
                if (onSuccess) {
                    // no data
                    onSuccess(null);
                }
            } else if (res?.data?.tickerTapeContent?.tickertapes) {
                SESSION.currentTickertapes.md5 = res?.data?.tickerTapeContent?.md5;
                if (onSuccess) {
                    const _allFullTickerTapes = res?.data?.tickerTapeContent?.tickertapes.map((_tt: TickerTape) => ({
                        ..._tt,
                        scheduleDailyType: ScheduleDailyType.TIME_RANGE,
                        scheduleObtrusiveMode: SESSION.isCommonZone() ? true : _tt.scheduleObtrusiveMode,
                        // @ts-ignore
                        content: _tt?.content ? JSON.parse(_tt.content) : {},
                    }));

                    onSuccess(_allFullTickerTapes);
                }
            } else if (res && (!res.data || (res.data && !res.data.tickerTapeContent) || res?.error)) {
                console.log("SOME ERRORS tickerTapeContent " + res?.error);
            }
        },
    });
};
