import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/common/button";
import { Texts } from "../../utils/texts";
import { BANNER, checkPriorityOfItems, getBannerMargins } from "../../utils/tickerTapeAndBannerUtils";
import { figmaHeightPx2Px, figmapx2vh, figmapx2vw, figmaWidthPx2Px } from "../../utils/utils";
import { useEffect, useRef, useState } from "preact/hooks";
import focus from "../../utils/focus";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { Theme } from "../../utils/theme";
import { setBannerOnScreen, setPopUp } from "../../actions/uiActions";
import { CONTENT, MENU_TYPES, MS_INTERVAL, POPUP_NAMES, SCREEN } from "../../utils/constants";
import { parseAction } from "../../utils/screenUtils";
import { Media } from "../../hooks/apis/media";
import { SESSION } from "../../utils/session";
import { SHA1 } from "../../utils/hash";
import { parseHourMinute, parseHourMinuteSeconds } from "../../utils/signageUtils";
import { moveFocus } from "../../utils/movement";

const Banner = ({ banners }) => {
    const dispatch = useDispatch();
    const { texts, popup, bannerOnScreen, showMenu, menu } = useSelector((state) => state.ui);

    const [banner, setBanner] = useState();
    const [bannerImageSize, setBannerImageSize] = useState(null);
    const [displayBanner, setDisplayBanner] = useState(false);

    const checkTimeout = useRef(null);
    const displayTimeout = useRef(null);
    const durationTimeout = useRef(null);
    const clock1s = useRef(null);
    const allBanners = useRef(null);

    if (!banners || banners.length === 0) {
        return null;
    }
    useEffect(() => {
        allBanners.current = banners;
        checkTimingOfBanners();
    }, [banners]);

    useEffect(() => {
        if (banner && SHA1(JSON.stringify(banner)) !== SESSION.currentBanners.sha1) {
            clearTimeouts();
            showBanner();
        }
    }, [banner]);

    const setNewBanner = (_banner) => {
        if (!_banner) {
            SESSION.currentBanners.sha1 = null;
            SESSION.currentBanners.id = null;
            setBanner(null);
            clearTimeouts();
            closeBanner();
            return;
        }
        if ((menu?.type === MENU_TYPES.HIDDEN || menu.temporaryType === MENU_TYPES.HIDDEN) && showMenu) {
            eventHandler.blockMovement(5000);
            setTimeout(() => {
                setBanner(_banner);
            }, 2000);
        } else {
            setBanner(_banner);
        }
    };

    const { y_align, x_align } = getBannerMargins(banner, bannerImageSize);
    const shorterThanBannerSize = useRef(false);

    const clearTimeouts = () => {
        if (durationTimeout.current) {
            clearTimeout(durationTimeout.current);
        }
        if (displayTimeout.current) {
            clearTimeout(displayTimeout.current);
        }
    };

    const setBannerImage = () => {
        let imageObj = new Image();

        imageObj.onload = function () {
            let _imgSize = {
                width: imageObj.offsetWidth,
                height: imageObj.offsetHeight,
                buttonsHeight: BANNER.SIZE.BUTTONS.height,
            };
            shorterThanBannerSize.current = false;
            if (
                imageObj.offsetWidth < figmaWidthPx2Px(BANNER.SIZE[banner.displaySize].width) &&
                imageObj.offsetWidth < figmaHeightPx2Px(224 * 2 + 64 + 12) &&
                banner.content.navigation
            ) {
                shorterThanBannerSize.current = true;
                _imgSize.buttonsHeight = 184;
            }
            setBannerImageSize(_imgSize);
        };
        imageObj.onerror = function () {};
        imageObj.src = banner.content.asset;
        imageObj.classList.add("block");
        imageObj.classList.add("mx-auto");
        const bannerImage = document.getElementById("banner_image");
        if (bannerImage.childElementCount > 0) {
            bannerImage.removeChild(bannerImage.firstChild);
        }
        if (bannerImage) {
            bannerImage.appendChild(imageObj);
        }
    };

    const checkTimingOfBanners = () => {
        if (checkTimeout.current) {
            clearTimeout(checkTimeout.current);
        }
        if (allBanners.current.length > 0) {
            const newBanners = checkPriorityOfItems(allBanners.current);
            const newBannerSha1 = newBanners?.[0] ? SHA1(JSON.stringify(newBanners[0])) : "";
            if (newBanners && newBanners.length > 0 && newBannerSha1 !== SESSION.currentBanners.sha1) {
                setNewBanner(newBanners?.[0]);
            } else if (!newBanners || newBanners.length === 0) {
                setNewBanner(null);
            }
        } else {
            if (banner) {
                // clear banner
                setNewBanner(null);
            }
        }
        checkTimeout.current = setTimeout(
            function () {
                checkTimingOfBanners();
            },

            MS_INTERVAL.ONE_MINUTE - new Date().getSeconds() * 1000,
        );
    };

    useEffect(() => {
        if (banner && !banner.scheduleObtrusiveMode && Media.isVideoFullScreen) {
            closeBanner();
        }
    }, [Media.isVideoFullScreen]);

    useEffect(() => {
        if (bannerImageSize) {
            dispatch(setBannerOnScreen(true));
            setDisplayBanner(true);
            document.querySelector("#banner_item").style.animation = "bannerFadeIn 1.8s";
        }
    }, [bannerImageSize]);

    const checkBannerRepetitions = () => {
        clearTimeouts();
        //check scheduleFrequencyLimited and scheduleDuration
        if (banner.scheduleFrequencyLimited) {
            if (banner.scheduleDuration) {
                // close banner
                const duration = parseHourMinute(banner.scheduleDuration);
                durationTimeout.current = setTimeout(
                    () => {
                        closeBanner();
                    },
                    (duration.hour * 60 + duration.min) * 1000,
                );
            }
            if (banner.scheduleFrequencyDisplay) {
                // re-show banner
                const displayTime = parseHourMinuteSeconds(banner.scheduleFrequencyDisplay);
                displayTimeout.current = setTimeout(
                    () => {
                        showBanner();
                    },
                    (displayTime.hour * 3600 + displayTime.min * 60 + displayTime.sec) * 1000,
                );
            }
        }
    };

    const showBanner = () => {
        if (popup && (popup === POPUP_NAMES.URGENTMESSAGES || popup === POPUP_NAMES.ALARMACTIVE)) {
            return;
        }
        if (banner.scheduleObtrusiveMode || (!banner.scheduleObtrusiveMode && !Media.isVideoFullScreen)) {
            dispatch(setPopUp("banner"));
            SESSION.currentBanners.sha1 = SHA1(JSON.stringify(banner));
            SESSION.currentBanners.id = banner.id;
            setBannerImage();
            setTimeout(function () {
                if (banner?.content?.navigation) {
                    focus.value.replace("btn-showMore");
                } else {
                    focus.value.replace("btn-bannerClose");
                }
            }, 700);
            checkBannerRepetitions();
        }
    };

    const closeBanner = () => {
        dispatch(setBannerOnScreen(false));
        dispatch(setPopUp(null));
        setDisplayBanner(false);
    };

    const onBannerKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        const allowKeys = [KEYS.up, KEYS.down, KEYS.left, KEYS.right];
        if (allowKeys.includes(keyData?.value)) {
            const itemId = moveFocus(focus.value.current, keyData.value);
            if (itemId) {
                focus.value.replace(itemId);
            }
        }
        // TODO: 3 veces home recarga interface ???
        if (keyData?.value == KEYS.enter) {
            document.activeElement.click();
        }
        if (keyData?.value != KEYS.home) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            id="banner_layout"
            className={`w-full h-full inline-flex absolute top-0`}
            style={{
                padding: figmapx2vw(72),
                backgroundColor: "rgba(87,101,117,0.7)",
                visibility: `${displayBanner ? "visible" : "hidden"}`,
                zIndex: 799,
            }}
            onKeyDown={(e) => {
                onBannerKeydown(e);
            }}
        >
            <div
                id="banner_item"
                className={`${y_align}`}
                style={{
                    width: bannerImageSize?.width + "px" || figmapx2vw(BANNER.SIZE[banner.displaySize].width),
                    height:
                        bannerImageSize?.height + figmaHeightPx2Px(bannerImageSize?.buttonsHeight) + "px" ||
                        figmapx2vh(BANNER.SIZE[banner.displaySize].height + BANNER.SIZE.BUTTONS.height),
                    marginLeft: x_align,
                    minWidth: figmapx2vw(288),
                }}
            >
                <div
                    id="banner_image"
                    className={"bg-gray-900"}
                    style={{
                        height: bannerImageSize?.height + "px" || figmapx2vh(BANNER.SIZE[banner.displaySize].height),
                    }}
                ></div>
                <div
                    id="banner_buttons"
                    className={`${
                        Theme.light ? "bg-white text-gray-900" : "bg-gray-800 text-gray-100"
                    } align-content-center w-full`}
                    style={{
                        paddingLeft: figmapx2vh(32),
                        paddingRight: figmapx2vh(32),
                        height: figmapx2vh(bannerImageSize?.buttonsHeight || BANNER.SIZE.BUTTONS.height),
                    }}
                >
                    <div className={`${shorterThanBannerSize.current ? "inline-block" : "inline-flex"} w-full h-full`}>
                        <div
                            className={`${
                                !banner?.content?.navigation
                                    ? "w-full"
                                    : shorterThanBannerSize.current
                                    ? "block"
                                    : " w-1/2"
                            } my-auto`}
                            style={{
                                marginTop: figmapx2vh(shorterThanBannerSize.current ? 24 : "auto"),
                                paddingRight: figmapx2vw(shorterThanBannerSize.current ? 0 : 12),
                            }}
                        >
                            <Button
                                insideModal={true}
                                data={{
                                    id: "bannerClose",
                                    name: Texts.capitalize(texts["close"]),
                                    border: true,
                                    customClass: ` py-2 px-4 text-lg text-center rounded mx-auto `,
                                    customStyle: {
                                        width: "100%",
                                        maxWidth: figmapx2vw(600),
                                        minWidth: figmapx2vw(224),
                                    },
                                    onClick() {
                                        closeBanner();
                                    },
                                }}
                            />
                        </div>
                        {banner?.content?.navigation && (
                            <div
                                className={`${shorterThanBannerSize.current ? "block" : "w-1/2"} my-auto`}
                                style={{
                                    marginTop: figmapx2vh(shorterThanBannerSize.current ? 24 : "auto"),
                                    paddingLeft: figmapx2vw(shorterThanBannerSize.current ? 0 : 12),
                                }}
                            >
                                <Button
                                    insideModal={true}
                                    data={{
                                        id: "showMore",
                                        name: Texts.translateCap(texts, "show more"),
                                        border: true,
                                        customClass: ` py-2 px-4 text-lg text-center rounded mx-auto ${
                                            Theme.light ? "bg-white text-gray-900" : "bg-gray-800 text-gray-100"
                                        }`,
                                        customStyle: {
                                            width: "100%",
                                            maxWidth: figmapx2vw(600),
                                            minWidth: figmapx2vw(224),
                                        },
                                        onClick() {
                                            //close and navigate
                                            closeBanner();

                                            eventHandler.dispatchNavigation({
                                                dispatch,
                                                action: parseAction(banner?.content?.navigation),
                                            });
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
