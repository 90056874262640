import { useEffect, useRef } from "react";
import { CHANNELS } from "../../utils/constants";
import focus from "../../utils/focus";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { figmapx2vh, figmapx2vw, getEnvVar, saveChannelToRestore } from "../../utils/utils";
import VerticalCenter from "../../components/common/verticalCenter";
import { useSelector } from "react-redux";

const ChannelDisplay = ({ playingChannel, tvState, setTvState, playPrevious, playNext, playByChannelNum }) => {
    const MENU_TIMEOUT = 4000; // in milliseconds
    let showTvMenuTimeout = useRef();
    let changeToChannelTimeout = useRef();
    const numberPress = useSelector((state) => state.events.numberPress);
    const popup = useSelector((state) => state.ui.popup);
    const rememberLastTvChannel = useSelector((state) => state.status.locationData.rememberLastTvChannel);

    useEffect(() => {
        focus.value.replace("channel-display-bar");
        saveChannelToRestore(playingChannel.id, rememberLastTvChannel);
        restartshowTvMenuTimeout();

        if (popup === "alarmActive") {
            focus.value.replace("tv-limbo");
            focus.value.stack();
            focus.value.replace("btn-stop");
        }

        return () => {
            clearTimeout(showTvMenuTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (popup) {
            clearTimeout(showTvMenuTimeout.current);
        } else {
            restartshowTvMenuTimeout();
        }
    }, [popup]);

    useEffect(() => {
        if (numberPress) {
            setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
            clearTimeout(changeToChannelTimeout.current);
            changeToChannelTimeout.current = setTimeout(() => {
                playByChannelNum(numberPress);
            }, 2000);
        }
    }, [numberPress]);

    const restartshowTvMenuTimeout = () => {
        setTvState(CHANNELS.TV_STATES.CHANNEL_DISPLAY);
        clearTimeout(showTvMenuTimeout.current);
        showTvMenuTimeout.current = setTimeout(() => {
            setTvState(CHANNELS.TV_STATES.LIMBO);
        }, MENU_TIMEOUT);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            restartshowTvMenuTimeout();
            switch (keyData.value) {
                case KEYS.enter:
                    setTvState(CHANNELS.TV_STATES.CHANNEL_INFO);
                    preventDefault = true;
                    break;
                case KEYS.up:
                case KEYS.channelUp:
                    playNext();
                    preventDefault = true;
                    break;
                case KEYS.down:
                case KEYS.channelDown:
                    playPrevious();
                    preventDefault = true;
                    break;
                case KEYS.left:
                case KEYS.right:
                    preventDefault = true;
                    break;
                case KEYS.back:
                    setTvState(CHANNELS.TV_STATES.LIMBO);
                    preventDefault = true;
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            id="channel-display-bar"
            className="navigable"
            tabIndex={0}
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <div className={"fixed top-0 lef-0 z-100 table w-full h-full "}>
                <div className="table-cell vertical-middle">
                    <div
                        className="channelDisplay"
                        style={{
                            height: `${figmapx2vh(160)}`,
                            width: `${figmapx2vw(854)}`,
                            paddingLeft: `${figmapx2vw(10)}`,
                        }}
                    >
                        <VerticalCenter
                            text={
                                <>
                                    {playingChannel?.logofile ? (
                                        <div
                                            className={`bg-center bg-contain bg-no-repeat float-left `}
                                            style={{
                                                width: "9vw",
                                                height: "10vh",
                                                borderRadius: `${figmapx2vw(4)}`,
                                                backgroundImage: `url('${
                                                    playingChannel.logoURL ||
                                                    `${getEnvVar("API")}ext/${
                                                        CHANNELS.logoPath.pRef
                                                    }/gfx_base/contents/tv/${
                                                        playingChannel.logofile
                                                    }?center_crop=120x120`
                                                }')`,
                                            }}
                                        />
                                    ) : (
                                        <div
                                            className={`white  icon-channels  float-left`}
                                            style={{
                                                fontSize: "3vw",
                                                width: "9vw",
                                                height: "10vh",
                                                lineHeight: "10vh",
                                            }}
                                        ></div>
                                    )}
                                    <div
                                        className=" float-left pl-4 nowrap text-ellipsis overflow-hidden"
                                        style={{
                                            fontSize: `${figmapx2vw(numberPress ? 40 : 32)}`,
                                            width: `33vw`,
                                            textAlign: "left",
                                        }}
                                    >
                                        {numberPress ? numberPress : `${playingChannel?.num} - ${playingChannel?.name}`}
                                    </div>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChannelDisplay;
