import focus from "../../utils/focus";
import { useEffect } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { STB } from "../../hooks/apis/stb";

const SessionInfo = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        focus.value.replace("btn-exit");
    }, []);

    const locationData = useSelector((state) => state.status.locationData);

    return (
        <div className="mt-8 text-center">
            <div className="bold text-base">Environment </div>{" "}
            <div style={{ color: "rgba(0,0,0,0.4)" }}>{process.env.PREACT_APP_API_URL}</div>{" "}
            <div className="bold text-base">Chain </div>{" "}
            <div style={{ color: "rgba(0,0,0,0.4)" }}>{locationData?.chainName}</div>{" "}
            <div className="bold text-base mt-8">Project </div>{" "}
            <div style={{ color: "rgba(0,0,0,0.4)" }}>{locationData?.projectName}</div>{" "}
            <div className="bold text-base mt-8">Room </div>{" "}
            <div style={{ color: "rgba(0,0,0,0.4)" }}>{locationData?.roomInfo?.name}</div>{" "}
            <div className="bold text-base mt-8">Room number </div>{" "}
            <div style={{ color: "rgba(0,0,0,0.4)" }}>{locationData?.roomInfo?.number}</div>{" "}
            <div className="bold text-base mt-8">Room Tv Name </div>{" "}
            <div style={{ color: "rgba(0,0,0,0.4)" }}>{locationData?.roomInfo?.roomTvName}</div>{" "}
            <div className="bold text-base mt-8">Guest </div>{" "}
            <div style={{ color: "rgba(0,0,0,0.4)" }}>{locationData?.guestInfo?.fullName}</div>{" "}
            <div className="bold text-base mt-8">Ref </div> <div style={{ color: "rgba(0,0,0,0.4)" }}>{STB?.ref}</div>{" "}
        </div>
    );
};

export default SessionInfo;
