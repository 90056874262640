export const KEYS = {
    power: "power",
    home: "home",
    enter: "enter",
    back: "back",
    lang: "lang",
    messages: "messages",
    bill: "bill",
    checkout: "checkout",
    channelUp: "channelUp",
    channelDown: "channelDown",
    up: "up",
    down: "down",
    left: "left",
    right: "right",
} as const;

export const KEYS_SOURCE = {
    RF: "rf",
    INFRARED: "infrared",
} as const;
